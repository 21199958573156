import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OrderService } from 'entities/Order/model';
import { User } from 'entities/Profile';
import { useForm } from 'features/Authentication';
import { OptionsForSend, SendOptions, useInitialSendOptions } from 'features/SendOptions';
import { PaymentError, PaymentService } from 'processes/Payment';
import { ID } from 'shared/model';
import { Button, DelayUnmountChild, Modal, ModalHeaderWithTitle, Success } from 'shared/ui';

import styles from './OrderPayment.module.scss';
import IllError from 'figma/components/IllError/IllError';
import { PreferenceKeys, PreferencesService } from 'processes/Preferences';

function OrderPayment(props: {
  orderService: OrderService;
  paymentService: PaymentService;
  preferencesService: PreferencesService;
  orderId: ID;
  getProfile: () => Promise<User>;
  onFinish: () => void;
}) {
  const [showPaymentOptions, setShowPaymentOptions] = useState<boolean>(false);
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const [error, setError] = useState<PaymentError>();
  const { t } = useTranslation();

  const brandColors = {
    colorThemeClubNavigation1: props.preferencesService?.get(PreferenceKeys.CLUB_NAVIGATION_1),
    colorThemeClubPrimaryLight: props.preferencesService?.get(PreferenceKeys.CLUB_PRIMARY_LIGHT),
  };

  const { formData: sendOptionsState, changeHandler, setFormData: setSendOptionsState } = useForm<OptionsForSend>({
    email: { value: '' },
    phone: { value: '' },
    sendToMail: { checked: true },
    sendToSMS: { checked: false },
  });

  useInitialSendOptions(() => props.getProfile(), sendOptionsState, setSendOptionsState);

  const moveToPayment = (onSucces: () => void, onError: (e: PaymentError) => void) => {
    props.paymentService.payOrder(props.orderId, onSucces, onError);
    setShowPaymentOptions(false);
  };

  const trackGoal = (tracker: string, idKey: PreferenceKeys, goalKey: PreferenceKeys) => {
    const metricaId = props.preferencesService?.getNullable(idKey);
    const metricaGoal = props.preferencesService?.getNullable(goalKey);

    if (!metricaId || !metricaGoal) {
      console.warn('Metrica ID or Goal is missing');
      return false;
    }

    switch (tracker) {
      case 'ym':
        (window as any).ym(metricaId, 'reachGoal', metricaGoal);
        break;
      case 'tmr':
        (window as any)._tmr.push({ type: 'reachGoal', id: metricaId, goal: metricaGoal });
        break;
      default:
        console.error('Unknown tracker:', tracker);
        return false;
    }

    return true;
  };

  // Yandex metrica
  const yandexMetrica = () => trackGoal('ym', PreferenceKeys.METRICA_ID, PreferenceKeys.METRICA_GOAL_BUY_TICKET);

  // Top Mail metrica
  const topMailMetrica = () => trackGoal('tmr', PreferenceKeys.VK_ID, PreferenceKeys.METRICA_GOAL_BUY_TICKET);

  return (
    <div className={styles.OrderPayment}>
      <Button id="GoPayment" onClick={() => setShowPaymentOptions(true)}>
        {t('order_payment.button') ?? `Перейти к оплате`}
      </Button>
      <DelayUnmountChild isMountChild={showPaymentOptions} delayTime={300}>
        <Modal visible={showPaymentOptions} closeModal={() => setShowPaymentOptions(false)} outsideClickClose={true}>
          <>
            <ModalHeaderWithTitle
              title={t('order_payment.modal_header') ?? 'Куда прислать билеты'}
              closeModal={() => setShowPaymentOptions(false)}
            />
            <SendOptions
              changeHandler={changeHandler}
              sendOptions={sendOptionsState}
              button={
                <Button
                  id="ContinueShopping"
                  onClick={async () => {
                    yandexMetrica();
                    topMailMetrica();
                    await props.orderService.changeNotificationSettings(props.orderId, {
                      enableEmail: sendOptionsState.sendToMail.checked,
                      enableSms: sendOptionsState.sendToSMS.checked,
                    });
                    moveToPayment(
                      () => {
                        props.orderService.refreshState();
                        setShowSuccessModal(true);
                      },
                      e => {
                        if (e.source !== 'provider') {
                          setError(e);
                        }
                      }
                    );
                    await props.orderService.markPaymentProcessing(props.orderId);
                  }}
                  disabled={!sendOptionsState.sendToMail.checked && !sendOptionsState.sendToSMS.checked}
                >
                  {t('order_payment.send_options_button') ?? 'Продолжить покупку'}
                </Button>
              }
            />
          </>
        </Modal>
      </DelayUnmountChild>

      <DelayUnmountChild isMountChild={showSuccessModal} delayTime={300}>
        <Modal visible={showSuccessModal} closeModal={() => setShowSuccessModal(false)}>
          <Success
            illustration="cartSuccess"
            title={t('order_payment.delay_unmount_child.success.title')}
            subtitle={`
            ${t('order_payment.delay_unmount_child.success.subtitle.0')} ${
              sendOptionsState.sendToMail.checked ? t('order_payment.delay_unmount_child.success.subtitle.1') : ''
            } ${
              sendOptionsState.sendToMail.checked && sendOptionsState.sendToSMS.checked
                ? ` ${t('order_payment.delay_unmount_child.success.subtitle.2')}`
                : ''
            } ${sendOptionsState.sendToSMS.checked ? t('order_payment.delay_unmount_child.success.subtitle.3') : ''}
            `}
            button={
              <Button id="ViewTickets" onClick={() => props.onFinish()}>
                {t('order_payment.delay_unmount_child.success.button') ?? 'Посмотреть билеты'}
              </Button>
            }
            brandColors={brandColors}
          />
        </Modal>
      </DelayUnmountChild>

      <DelayUnmountChild isMountChild={error !== undefined} delayTime={300}>
        <Modal visible={error !== undefined} closeModal={() => setError(undefined)}>
          <ModalHeaderWithTitle
            title={t('order_payment.delay_unmount_child.error.title') ?? 'Ошибка оплаты'}
            closeModal={() => setError(undefined)}
          />
          <Success
            illustration={<IllError />}
            title={''}
            subtitle={t('order_payment.delay_unmount_child.error.success_subtitle') ?? 'Попробуйте еще раз'}
            brandColors={brandColors}
          />
        </Modal>
      </DelayUnmountChild>
    </div>
  );
}

export default OrderPayment;
