import { BehaviorSubject } from 'rxjs';
import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';
import { deserialize } from '@sebbia/object-deserializer';
import { orderDeserializer, promocodeDeserializer } from './deserializers';
import { Order, OrderNotificationSettings, OrderService, ReserveResult } from './types';
import { AuthService } from 'processes/Auth';
import { ID } from 'shared/model';
import { Money } from 'shared/utils';
import { ORDER_FRAGMENT_FIELDS, PROMOCODE_DESCRIPTOR_FRAGMENT } from './fragments';
import { Promocode } from './coupon.types';

class OrderServiceImpl implements OrderService {
  behaviorOrder: BehaviorSubject<Promise<Order | undefined>> = new BehaviorSubject(this.getCurrentOnly());

  constructor(private client: ApolloClient<NormalizedCacheObject>, private auth: AuthService) {}

  async changeNotificationSettings(orderId: string, settings: OrderNotificationSettings): Promise<void> {
    const mutation = gql`
      mutation($orderId: ID!, $data: Tickets_OrderNotificationPropertiesInput!) {
        order {
          setOrderNotification(orderId: $orderId, data: $data) {
            id
            notificationProperties {
              enableEmail
              enableSms
              overridePhone
              overrideEmail
            }
          }
        }
      }
    `;
    const res = await this.client.mutate({
      variables: { orderId: orderId, data: settings },
      mutation,
    });
    if (res.errors) {
      throw Error('<46991f6a> Can`t apply order notification settings');
    }
  }

  async refreshState(): Promise<void> {
    this.behaviorOrder.next(this.getCurrentOnly());
  }

  async getCurrentOnly(userID?: ID): Promise<Order | undefined> {
    const query = gql`
      ${ORDER_FRAGMENT_FIELDS}
      query getCurrentOnly($userId: ID) {
        order {
          getCurrentOnly(userId: $userId) {
            ...OrderFields
          }
        }
      }
    `;
    if (this.auth.isAuthorized) {
      const res = await this.client.query({ query });
      return deserialize(res.data, o => o.optional('order.getCurrentOnly')?.asObject(o => orderDeserializer(o)));
    }
  }

  async removeItemFromOrder(orderId: ID, itemIds: ID[]): Promise<Order> {
    const mutation = gql`
      ${ORDER_FRAGMENT_FIELDS}
      mutation removeItemFromOrder($orderId: ID!, $itemIds: [ID!]!) {
        order {
          removeItems(orderId: $orderId, itemIds: $itemIds) {
            ...OrderFields
          }
        }
      }
    `;

    const res = await this.client.mutate({
      variables: { orderId: orderId, itemIds: itemIds },
      mutation,
    });
    const deserealizeRes = await deserialize(res.data, o =>
      o.required('order.removeItems').asObject(o => orderDeserializer(o))
    );
    this.behaviorOrder?.next(Promise.resolve(deserealizeRes));
    return deserealizeRes;
  }

  async applyLoyaltyBonuses(orderId: ID, value: Money): Promise<Order> {
    const mutation = gql`
      ${ORDER_FRAGMENT_FIELDS}
      mutation applyLoyaltyBonuses($orderId: ID!, $value: Money!) {
        order {
          applyLoyaltyBonuses(orderId: $orderId, value: $value) {
            ...OrderFields
          }
        }
      }
    `;

    const res = await this.client.mutate({
      variables: { orderId: orderId, value: value.value.toString() },
      mutation,
    });
    const deserealizeRes = await deserialize(res.data, o =>
      o.required('order.applyLoyaltyBonuses').asObject(o => orderDeserializer(o))
    );
    this.behaviorOrder?.next(Promise.resolve(deserealizeRes));
    return deserealizeRes;
  }

  async applyPromocode(orderId: ID, promocode: string): Promise<Order> {
    const mutation = gql`
      ${ORDER_FRAGMENT_FIELDS}
      mutation applyPromocode($orderId: ID!, $promocode: String!) {
        order {
          applyPromocode(orderId: $orderId, promocode: $promocode) {
            ...OrderFields
          }
        }
      }
    `;

    const res = await this.client.mutate({
      variables: { orderId: orderId, promocode: promocode },
      mutation,
    });
    const deserealizeRes = await deserialize(res.data, o =>
      o.required('order.applyPromocode').asObject(o => orderDeserializer(o))
    );
    this.behaviorOrder?.next(Promise.resolve(deserealizeRes));
    return deserealizeRes;
  }

  async cancelPromocode(orderId: ID): Promise<Order> {
    const mutation = gql`
      ${ORDER_FRAGMENT_FIELDS}
      mutation cancelPromocode($orderId: ID!) {
        order {
          cancelPromocode(orderId: $orderId) {
            ...OrderFields
          }
        }
      }
    `;

    const res = await this.client.mutate({ variables: { orderId: orderId }, mutation });
    const deserealizeRes = await deserialize(res.data, o =>
      o.required('order.cancelPromocode').asObject(o => orderDeserializer(o))
    );
    this.behaviorOrder?.next(Promise.resolve(deserealizeRes));
    return deserealizeRes;
  }

  async getUserCoupons(userId?: ID): Promise<Promocode[]> {
    const query = gql`
      ${PROMOCODE_DESCRIPTOR_FRAGMENT}
      query getCouponByUserId($userId: ID!) {
        promocodes {
          getUserCoupons(userId: $userId) {
            value
            useCounter
            descriptor {
              ...PromocodeDescriptorFragment
            }
          }
        }
      }
    `;
    if (this.auth.isAuthorized) {
      const response = await this.client.query({
        variables: {
          userId: userId,
        },
        query,
      });
      const coupons = deserialize(response.data, o =>
        o.required('promocodes.getUserCoupons')?.asArrayOfObjects(o => promocodeDeserializer(o))
      );
      return coupons?.filter(coupon => {
        // Фильтруем купоны с истекшим сроком действия (позже это должно быть на бэкенде)
        if (coupon.descriptor.period.end === undefined) {
          return true;
        } else {
          return coupon.descriptor.period.end.getTime() > new Date().getTime();
        }
      });
    }
    return [];
  }

  async sendToSMS(orderId: ID, phone: string): Promise<boolean> {
    const mutation = gql`
      mutation sendToSms($orderId: ID!, $phone: String!) {
        order {
          sendToCustomPhone(orderId: $orderId, phone: $phone)
        }
      }
    `;

    const res = await this.client.mutate({
      variables: { orderId: orderId, phone: phone },
      mutation,
    });
    return deserialize(res.data, o => o.required('order.sendToCustomPhone').asBool);
  }

  async sendToMail(orderId: ID, email: string): Promise<boolean> {
    const mutation = gql`
      mutation sendToMail($orderId: ID!, $email: String!) {
        order {
          sendToCustomEmail(orderId: $orderId, email: $email)
        }
      }
    `;

    const res = await this.client.mutate({
      variables: { orderId: orderId, email: email },
      mutation,
    });
    return deserialize(res.data, o => o.required('order.sendToCustomEmail').asBool);
  }

  async markPaymentProcessing(orderId: ID): Promise<boolean> {
    const mutation = gql`
      mutation markPaymentProcessing($orderID: ID!) {
        order {
          markPaymentProcessing(orderId: $orderID) {
            id
            status
          }
        }
      }
    `;
    try {
      const res = await this.client.mutate({
        variables: { orderID: orderId }, // Исправлено: orderID с заглавной буквы
        mutation,
      });

      const isSuccess = !!res?.data?.order?.markPaymentProcessing;

      return isSuccess;
    } catch (error) {
      console.error('Ошибка при выполнении markPaymentProcessing:', error);
      return false; // Возвращаем false в случае ошибки
    }
  }

  async reserveTicket(placeId: string, matchId: string): Promise<ReserveResult> {
    const mutation = gql`
      ${ORDER_FRAGMENT_FIELDS}
      mutation SHOP_ReserveMatchPlace($placeId: ID!, $matchId: ID!) {
        ticket {
          create(data: { eventId: $matchId, placeIds: [$placeId], tag: ONLINE }) {
            id
            orderItem {
              id
            }
            order {
              ...OrderFields
            }
          }
        }
      }
    `;
    const res = await this.client.mutate({ variables: { placeId, matchId }, mutation });
    const deserealizeOrderRes = deserialize(res.data, o =>
      o.required('ticket.create').asArrayOfObjects(arr => {
        return {
          order: arr.required('order').asObject(o => orderDeserializer(o)),
          id: arr.required('id').asString,
          orderItemId: arr.required('orderItem.id').asString,
        };
      })
    );
    this.behaviorOrder?.next(Promise.resolve(deserealizeOrderRes[0].order));
    return deserealizeOrderRes[0];
  }

  async reserveSeasonTicket(placeId: string, descriptorId: string): Promise<ReserveResult> {
    const mutation = gql`
      ${ORDER_FRAGMENT_FIELDS}
      mutation SHOP_ReserveSeasonTicketPlace($placeId: ID!, $descriptorId: ID!) {
        seasonTicket {
          create(data: { seasonTicketDescriptorId: $descriptorId, placeIds: [$placeId] }) {
            id
            orderItem {
              id
            }
            order {
              ...OrderFields
            }
          }
        }
      }
    `;
    const res = await this.client.mutate({ variables: { placeId, descriptorId }, mutation });
    const deserealizeRes = await deserialize(res.data, o =>
      o.required('seasonTicket.create').asArrayOfObjects(arr => {
        return {
          order: arr.required('order').asObject(o => orderDeserializer(o)),
          id: arr.required('id').asString,
          orderItemId: arr.required('orderItem.id').asString,
        };
      })
    );
    this.behaviorOrder?.next(Promise.resolve(deserealizeRes[0].order));
    return deserealizeRes[0];
  }
}

export default OrderServiceImpl;
