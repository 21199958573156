
import { PlacePrice, PlaceStatus } from "entities/Venue";
import { Money } from "shared/utils";
import { ItemType, Order, OrderService, RemovePlacesFromOrderParams, ReservePlaceParams, GoToCartParams, SeasonTicket, Ticket, ReserveResult } from "./types";
import { CancelError, PlacesReservationError, ReservationError, isError } from "entities/Venue/utils/types";
import ensureAuthorized from "features/Authentication/shared/utils/ensureAuthorized";

export const checkType = (eventType: ItemType, type: ItemType, updateEventType: (type: ItemType) => void) => {
  if (eventType !== type) {
    updateEventType(type);
  }
}

export const countByType = (eventType: ItemType, order: Order) => {
  const filteredArr = order?.items.filter(item => item.type === eventType);
  return filteredArr?.length ? filteredArr?.length : 0;
}

const prepareReservedItem = (item: any, preparedArr: PlacePrice[]) => {
  let preparedItem: PlacePrice = {
    color: '',
    price: new Money(0),
    priceWithDiscount: new Money(0),
    priceCategoryId: '',
    status: PlaceStatus.BLOCKED,
    place: {
      coordinates: {
        x: 0,
        y: 0
      },
      id: '',
      number: '',
      rowNumber: '',
      sectorTitle: '',
    }
  }

  preparedItem.color = item.item.color;
  preparedItem.price = item.item.price;
  preparedItem.priceWithDiscount = item.item.price;
  preparedItem.priceCategoryId = item.item.priceCategoryId;
  preparedItem.place.coordinates = item.item.place.coordinates;
  preparedItem.place.id = item.item.place.id;
  preparedItem.place.number = item.item.place.number;
  preparedItem.place.rowNumber = item.item.place.row.number;
  preparedItem.place.sectorTitle = item.item.place.row.sector.title;

  preparedArr.push(preparedItem);
  return preparedArr;
}

export const prepareReservedPlacesArr = (currentOrder: Order, eventId: string) => {
  let preparedArr: PlacePrice[] = [];
  if (currentOrder) {
    for (let i = 0; i < currentOrder.items.length; i++) {
      const item = currentOrder.items[i];

      if ((item.type === (ItemType.TICKET || ItemType.OUTFIT)) && ((item.item as Ticket).event.id === eventId)) {
        preparedArr = prepareReservedItem(item, preparedArr);
      }
      if ((item.type === (ItemType.SEASON_TICKET || ItemType.PROLONG_SEASON_TICKET) && ((item.item as SeasonTicket).descriptor.id === eventId))) {
        preparedArr = prepareReservedItem(item, preparedArr);
      }
    }
  }

  return preparedArr;
}

const ticketReserve = async (obj: PlacePrice, orderService: OrderService, id: string) => {
  try {
    return await orderService.reserveTicket(obj.place.id, id);
  } catch (e) {
    const error = e as Error;
    if (error.message.includes('d0bb35a8')) return { errorType: 'MatchNotAvailable' } as PlacesReservationError;
    return { errorType: 'UnknownError' } as PlacesReservationError;
  }
};

const seasonTicketReserve = async (obj: PlacePrice, orderService: OrderService, id: string) => {
  try {
    return await orderService.reserveSeasonTicket(obj.place.id, id);
  } catch (e) {
    const error = e as Error;
    if (error.message.includes('1a20309d')) {
      return { errorType: 'SeasonTicketNotAvailable' } as PlacesReservationError;
    } else {
      throw error;
    }
  }
};

export const instantReserve = async ({place, orderService, id, eventType}: ReservePlaceParams): Promise<PlacesReservationError | ReserveResult> => {
  let reserveResult;
  try {
    if (eventType === ItemType.TICKET) {
      reserveResult = await ticketReserve(place, orderService, id);
    } else {
      reserveResult = await seasonTicketReserve(place, orderService, id);
    }
    if (isError(reserveResult)) {
      return reserveResult as PlacesReservationError;
    }
    return reserveResult;
  } catch (e) {
    return { placePrice: place, errorType: 'ReservationError' } as ReservationError;
  }
}

export const instantRemovePlacesFromOrder = async ({places, orderItemsIds, orderService}: RemovePlacesFromOrderParams): Promise<CancelError | undefined> => {
  try {
    const orderId = orderItemsIds[places[0].place.id].orderId;
    let orderItems: string[] = [];
    places.map(item => orderItems.push(orderItemsIds[item.place.id].orderItemId));
    await orderService.removeItemFromOrder(orderId, orderItems);
    return;
  } catch (e) {
    return { errorType: 'CancelError' } as CancelError;
  }
}

export const instantGoToCard = async ({authService, orderService, id, reservedPlaces, updateReservedPlaces, redirectFunc, eventType}: GoToCartParams): Promise<void | PlacesReservationError> => {
  updateReservedPlaces([]);
  redirectFunc();
}

export const packedGoToCart = ({authService, orderService, id, reservedPlaces, updateReservedPlaces, redirectFunc, eventType}: GoToCartParams): Promise<void | PlacesReservationError> => {
  return new Promise((resolve, reject) => {
    ensureAuthorized(
      authService,
      async () => {
        const results = await Promise.all(
          reservedPlaces.map(async place => {
            if (eventType === ItemType.TICKET) {
              return { result: await ticketReserve(place, orderService, id), place };
            } else {
              return { result: await seasonTicketReserve(place, orderService, id), place };
            }
          })
        );

        updateReservedPlaces([]);

        const errors = results.filter(it => isError(it.result));
        if (errors.length > 0) {
          resolve({
            errorType: 'PackedReservationError',
            places: errors.map(it => it.place),
          } as PlacesReservationError);
        }

        redirectFunc();
        resolve();
      },
      () => {
        resolve({ errorType: 'AuthorizationRequired' });
      }
    )
  })
}
